.report-song {
  text-transform: uppercase;
  position: relative;

  .success-image {
    margin-top: 4rem;
  }

  .success-text {
    text-transform: initial;
    text-align: center;
    margin-top: 2rem;
  }
  &__details {
    display: flex;
    font-size: 1.4rem;
    margin: 2rem 0;
    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      margin-right: 0.5rem;
    }
    .song-title {
      font-weight: 700;
      margin-right: 0.5rem;
    }
  }

  &__form {
    width: 100%;

    .type {
      display: flex;
      align-items: center;
      margin: 1rem 0 0 3rem;
      cursor: pointer;
      img {
        margin-right: 1rem;
      }
    }

    textarea {
      width: 100%;
      margin-top: 2rem;
      padding: 1rem;
      font-family: $quicksand;
      font-size: 16px;
      &::placeholder {
        font-family: inherit;
      }
      &:focus {
        outline: none;
      }
    }
  }

  button {
    position: absolute;
    bottom: 30px;
    width: 80%;
  }
}
