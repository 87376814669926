.soundapp_container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  *,
  *::before,
  *::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  .soundApp {
    max-width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Quicksand", sans-serif;
    color: black;

    &__header {
      width: 100%;
      background-color: white;
      position: sticky;
      z-index: 5000;
      top: 0;
      // left: 0;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 8px 30px 0px rgba($color: #000000, $alpha: 0.06);
      .soundapp_nav {
        position: absolute;
        width: 301px;
        background-color: black;
        top: 87px;
        left: 37px;
        display: none;

        &.visible {
          display: block;
          z-index: 7000;
        }
        ul {
          list-style-type: none;
          font-size: 1.4rem;
          li {
            font-family: "renner", sans-serif;
            color: $color-red;
            padding: 18px 0 18px 18px;
            opacity: 0;
            animation: moveInLeft 0.4s forwards;
            &.incomplete-registration {
              background-color: #ec3432;
              a {
                color: black;
                span:first-child {
                  margin-right: 30px;
                }
              }
            }
          }
        }
      }
      .logo-menu {
        padding: 35px 37px 0;
        width: 301px;
        .logo-container {
          width: 301px;
          position: relative;
          img.soundapp_logo {
            width: 301px;
            height: 49px;
            // object-fit: cover;
          }
          .soundapp_incomplete {
            position: absolute;
            width: 20px;
            top: -40px;
            right: -5px;
          }
        }
      }
      .now-playing {
        font-family: "renner", sans-serif;
        text-transform: uppercase;
        display: flex;
        // margin-top: 15px;
        width: 301px;
        margin: 15px auto 0 auto;
        // padding: 0 37px;
        position: relative;
        top: 6px;
        // align-self: start;
        .playing {
          color: $color-red;
          font-size: 16px;
          margin-right: 46px;
        }
        .beat-info {
          display: flex;
          transform: translateY(5px);
          opacity: 0;
          transition: all 0.3s;
          font-size: 1.4rem;
          &.active {
            opacity: 1;
            transform: translateY(2px);
          }
          .producer {
            font-weight: bold;
            margin-right: 5px;
          }
          .beat {
            font-family: "renner-light", sans-serif;
          }
        }
      }
    }

    &__player {
      max-width: 375px;
      // position: relative;
      // top: 84px;
      // margin-top: 118px;
      // background-color: rgba($color: #e5e5e5, $alpha: 0.4);
      padding: 0 37px;
      z-index: 2000;
      .player-wrapper {
        padding-top: 35px;
        display: flex;
        justify-content: space-between;
        width: 301px;
        .album-art {
          width: 124px;
          height: 125px;
          position: relative;
          box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.55);
          .art {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
            &.paused {
              filter: brightness(70%);
            }
          }
          .play-pause {
            position: absolute;
            width: 28px;
            height: 28px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .right {
          width: 50%;
          height: 125px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .beat-details {
            font-family: "renner", sans-serif;
            text-transform: uppercase;
            line-height: 1.4;
            .producer-name {
              font-weight: bold;
            }
            .beat-name {
              font-family: "renner-light", sans-serif;
            }
            .info {
              font-family: "renner-light", sans-serif;
            }
          }
          .actions {
            display: flex;
            &__item {
              margin-right: 20px;
              text-align: center;
              text-decoration: none;
              color: black;
              img {
                width: 20.73px;
                height: 20.73px;
              }
              .icon-text {
                font-size: 9px;
                margin-top: 7px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    .progress-bar {
      padding: 35px 0;
      width: 301px;
      position: sticky;
      top: 115px;
      z-index: 1000;
      background-color: white;
      cursor: pointer;
      .background {
        background-color: #dadada;
        height: 1px;
        width: 100%;
        position: relative;
      }
      .progress {
        position: absolute;
        top: 0;
        height: 1px;
        background-color: $color-red;
      }
    }

    &__songs-tray {
      width: 375px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // height: 630px;
      overflow-y: hidden;
      position: relative;
      z-index: 4000;
      // top: 84px;
      box-shadow: 0px -8px 30px rgba(0, 0, 0, 0.06);
      .headings {
        padding: 37px 0;
        width: 100%;
        // position: sticky;
        // top: 175px;
        text-transform: uppercase;
        // box-shadow: 0px -8px 30px rgba(0, 0, 0, 0.06);
        // z-index: 4000;
        background-color: white;

        .wrapper {
          width: 301px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;

          .headings__item {
            position: relative;
            text-decoration: none;
            color: black;
            font-weight: 300;
            &.selected {
              &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 30px;
                transform: translate(-50%, -50%);
                background-color: $color-red;
                width: 33.5px;
                height: 1px;
              }
            }
          }
        }
      }

      .songs-empty {
        width: 80%;
        margin: 40px auto 0;
        text-align: center;
        line-height: 1.4;
      }
      .songs-loading {
        width: 80%;
        margin: 40px auto 0;
        text-align: center;
        line-height: 1.4;
      }
      .songs {
        width: 60%;
        margin: 10px auto 0;

        overflow-y: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        &__grid {
          width: 100%;
          display: flex;
          margin-bottom: 20px;
          .song-wrapper {
            text-align: center;
            text-transform: uppercase;
            font-size: 9.5px;
            text-decoration: none;
            color: black;
            opacity: 1;
            transition: all 0.3s ease-in-out;

            // &.fadeout {
            //   opacity: 0;
            // }
            img {
              height: 22px;
              width: 22px;
              margin-bottom: 2px;
            }
            .producer {
              font-weight: bold;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
}

@keyframes moveInLeft {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
