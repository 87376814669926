.uploadBeat {
  width: 40%;
  font-family: $quicksand;
  @include respond(phone) {
    width: 100%;
  }
  &__success {
    width: 100%;
    text-align: center;
    padding-top: 4rem;
  }
  &__title {
    font-family: $jost;
    text-transform: uppercase;
    font-size: 3rem;
    img {
      width: 3rem;
    }
  }
  &__form {
    .info {
      font-size: 1.4rem;
    }
  }
  &__ownership {
    margin: 2rem 0;

    font-size: 1.4rem;
    input {
      margin-right: 1rem;
    }
  }
  &__protect {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    h3 {
      text-transform: uppercase;
      margin-left: 2rem;

      font-weight: 400;
    }
  }
  &__vow {
    font-size: 1.4rem;
  }
}
