.signup {
  width: 100vw;
  height: 100vh;
  font-family: $quicksand;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  background: black;
  &.producer-challenge {
    justify-content: start;
    background: none;
    color: black;
    .step-2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .success {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @include respond(tab-port) {
        width: 90%;
        margin-top: -3rem;
      }
      h1 {
        font-family: $jost;
      }
      img {
        width: 200px;
        height: 200px;
        margin-top: 10rem;
      }
      p {
        font-weight: bold;
        margin-top: 2rem;
        width: 70%;
        @include respond(tab-port) {
          width: 95%;
        }
      }
    }
    .multistep {
      z-index: 100;
      margin: 4rem 0 6rem 0;
    }
    .signup__step1-wrapper {
      color: black;
      margin-top: 0rem;
      .input-text {
        border-bottom: 1px solid black;
        color: black;
        &::placeholder {
          color: black;
        }
      }
      h3 {
        font-size: 1.6rem;
        margin: 6rem 0 4rem 0;
        font-weight: 100;
        font-display: $jost;
      }
      .white-btn,
      .white-btn.ns {
        background-color: transparent;
        box-shadow: none;
        color: black;
        border: 1px solid black;
        border-radius: 12px;
        transition: all 0.2s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
      }
      .white-btn.ns {
        border: none;
      }
    }
  }
  video {
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    object-fit: cover;
  }
  & > * {
    width: 30%;
    @include respond(tab-port) {
      width: 60%;
    }
    @include respond(phone) {
      width: 90%;
    }
  }
  &__title {
    margin-bottom: 3rem;
    text-align: left;
    z-index: 10;
  }
  &__correct-payment {
    margin: -1rem 0 1rem 0;
    font-size: 1.8rem;
    font-family: $quicksand;
    text-transform: uppercase;
    z-index: 10;

    @include respond(tab-port) {
      font-size: 1.4rem;
    }
  }
  .signup__card {
    z-index: 1;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.05);
    color: white;
    border-radius: 20px;
    width: 30%;
    -webkit-box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 2px rgba(255, 255, 255, 0.1), 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 150px rgba(255, 255, 255, 0.08), 0 0 20px rgba(255, 255, 255, 0.1),
      0 0 150px rgba(255, 255, 255, 0.08);

    @include media('<phone') {
      width: 100%;
      padding: 10px;
    }
    @include media('>=phone', '<lgphone') {
      width: 90%;
      padding: 10px;
    }
    @include media('>=lgphone', '<tablet') {
      width: 90%;
      padding: 10px;
    }
    @include media('>=tablet', '<=desktop') {
      width: 50%;
      padding: 20px;
    }
    .heading {
      margin-bottom: 20px;
      font-weight: 500;
    }
    .input-text {
      width: 100%;
      margin: 1rem 0rem;
      border: none;
      padding: 1.5rem;
      font-size: 16px;
      background-color: transparent;
      border: 1px solid transparent;
      font-weight: 500;
      font-family: $jost;
      border-bottom: 1px solid white;
      color: white;

      /* Change Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: white !important;
        -webkit-box-shadow: 0 0 0 30px inset !important;
        border-bottom: 1px solid white !important;
        border: 0px;
      }
      &:first-child {
        margin-right: 1rem;
      }
      &::placeholder {
        color: white;
        opacity: 0.4;
        font-weight: 500;
      }
      &:focus {
        outline: none;
      }
    }
    .signup__info {
      color: grey;
    }
    .white-btn {
      border-radius: 12px;
      transition: all 0.2s ease-in-out;
      background: transparent;
      box-shadow: none;
      border: 1px solid white;
      color: white;
      &:hover {
        transform: scale(1.1);
      }
      &.ns {
        background-color: transparent;
        color: white !important;
        border: 0px;
      }
    }
    img {
      height: 50px;
    }
  }
  &__step1 {
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    z-index: 0;
    .card-expiry-cvv {
      .card-expiry {
        display: flex;
        align-items: center;
      }
    }
    .signup__step1-wrapper {
      width: 40%;
      height: 100%;
      padding: 2rem;
      margin-left: 10rem;
      border-radius: 20px;
      color: white;
      @include media('<phone') {
        width: 100%;
        padding: 10px;
        margin-left: 1rem;
      }
      @include media('>=phone', '<lgphone') {
        width: 90%;
        padding: 10px;
        margin-left: 1rem;
      }
      @include media('>=lgphone', '<tablet') {
        width: 90%;
        margin-left: 1rem;
        padding: 10px;
      }
      @include media('>=tablet', '<=desktop') {
        width: 100%;
        padding: 20px;
        margin-left: 1rem;
      }
      h1 {
        font-weight: 500;
        font-family: $jost;
      }
      .input-select {
        background-color: transparent;
        border-bottom: 1px solid white;
        color: white;
        font-family: $jost;
        font-weight: 500;
        text-transform: uppercase;
      }
      .input-text {
        background-color: transparent;
        border-bottom: 1px solid white;
        color: white;
        font-weight: 500;
        font-family: $jost;
        /* Change Autocomplete styles in Chrome*/
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          -webkit-text-fill-color: white !important;
          -webkit-box-shadow: 0 0 0 30px inset !important;
          border-bottom: 1px solid white !important;
          border: 0px;
        }
        &::placeholder {
          color: #95a5a6;
          font-weight: 400;
          font-family: $jost;
        }
      }
      .white-btn {
        background-color: transparent;
        box-shadow: none;
        color: white;
        border: 1px solid white;
        border-radius: 12px;
        transition: all 0.2s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
      }
      .ns {
        background-color: transparent;
        border: 0px;
      }
      .signup__info {
        color: black;
      }
    }
    .signup__step1-card-wrapper {
      z-index: 10;
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include media('<phone') {
        display: none;
      }

      @include media('>=phone', '<lgphone') {
        display: none;
      }
      @include media('>=lgphone', '<tablet') {
        display: none;
      }
      @include media('>=tablet', '<=desktop') {
        display: none;
      }

      .transparent-blob {
        position: absolute;
        transform: scale(2.5);
      }
      .card {
        position: relative;
        height: 270px;
        width: 450px;
        border-radius: 25px;
        background: rgba(255, 255, 255, 0.15);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(8.5px);
        -webkit-backdrop-filter: blur(8.5px);
        border: 1px solid rgba(255, 255, 255, 0.18);
        overflow: hidden;
        font-family: $jost;
        font-weight: 500;
        color: black;
      }

      .logo img,
      .chip img,
      .number,
      .name,
      .from,
      .to,
      .ring {
        position: absolute; /* All items inside card should have absolute position */
      }

      .logo img {
        top: 10px;
        right: 40px;
        width: 80px;
        height: auto;
        opacity: 0.8;
      }

      .chip img {
        top: 30px;
        left: 40px;
        width: 60px;
        height: auto;
        opacity: 0.8;
      }

      .number,
      .name,
      .from,
      .to {
        color: black;
        font-weight: 400;
        letter-spacing: 2px;
      }

      .number {
        left: 40px;
        top: 125px;
        font-family: 'Nunito', sans-serif;
        font-size: 2.2rem;
      }

      .name {
        font-size: 2rem;
        left: 40px;
        bottom: 35px;
        text-transform: uppercase;
      }

      .from {
        font-size: 1.5rem;
        bottom: 40px;
        right: 110px;
      }

      .to {
        font-size: 1.5rem;
        bottom: 40px;
        right: 40px;
      }
    }
  }

  &__actions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .white-btn {
      border-radius: 12px;
      transition: all 0.2s ease-in-out;
      color: white;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &__info {
    font-family: $quicksand;
    text-align: center;
    margin-top: 3rem;
    color: $color-red;
    font-size: 1.4rem;
    line-height: 1.4;
    color: white;
  }
  &__success {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 3rem;
    }
    p {
      text-align: center;
      font-family: $quicksand;
      margin: 2rem 0;
      font-size: 1.4rem;
      opacity: 0.7;
      span {
        color: $color-red;
      }
    }
  }
}

.secret-service {
  background-color: black;
  color: $color-red;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 1);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem;
  height: 600px;
  position: relative;
  &__loading {
    position: absolute;
    top: 0;
    z-index: 10;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
  &__error {
    margin-top: 2rem;
  }
  &__top {
    width: 100%;
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
    }
    h1 {
      font-size: 4.5rem;
      font-weight: 400;
    }
    img {
      cursor: pointer;
    }
  }

  &__content {
    margin-top: 10rem;
    &.no-secret {
      margin-top: 6rem;
      h4 {
        position: relative;
        top: 2rem;
      }
    }
    h3 {
      text-transform: uppercase;
      font-size: 1.6rem;
      position: relative;
      top: 1.5rem;
    }
    h4 {
      font-size: 2.5rem;
      text-transform: uppercase;
    }
    p {
      text-transform: uppercase;
      font-size: 1rem;
      margin-top: 0.5rem;
      opacity: 0.6;
    }
  }
  &__display {
    width: 100%;
    text-align: center;
    border: 1px solid $color-red;
    // padding: 16px 0;
    font-size: 4rem;
    margin-top: 3rem;
    position: relative;
    .display-input {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -10;
      font-size: 16px;
      opacity: 0;
      // visibility: hidden;
    }
  }
  &__button {
    position: absolute;
    bottom: 40px;
    font-size: 2rem;
    background-color: $color-red;
    text-transform: uppercase;
    color: black;
    padding: 1rem 2rem;
    cursor: pointer;
  }
  &__other-plans {
    margin-top: 5rem;

    h3 {
      font-size: 1.5rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    .plan-container {
      width: 100%;
      margin: 0 auto;
      border: 1px solid $color-red;
      font-size: 1.8rem;

      .plan {
        padding: 1rem;
        cursor: pointer;
        &.active {
          background-color: $color-red;
          color: black;
        }
      }
    }
  }
}
