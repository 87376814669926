.signin {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  align-items: center;
  justify-content: center !important;
  background-color: black;
  overflow: hidden;
  @include respond(tab-port) {
    justify-content: center;
  }
  &__title {
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 3rem;
    z-index: 100;
    font-weight: 500;
    @include respond(tab-port) {
      width: 90%;
      margin-bottom: 2rem;
    }
  }
  .signin__card {
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.05);
    color: white;
    border-radius: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 2px rgba(255, 255, 255, 0.1), 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 150px rgba(255, 255, 255, 0.08), 0 0 20px rgba(255, 255, 255, 0.1),
      0 0 150px rgba(255, 255, 255, 0.08);

    @include media("<phone") {
      width: 100%;
      padding: 10px;
    }

    @include media(">=phone", "<lgphone") {
      width: 90%;
      padding: 10px;
    }
    @include media(">=lgphone", "<tablet") {
      width: 90%;
      padding: 10px;
    }
    @include media(">=tablet", "<=desktop") {
      width: 50%;
      padding: 20px;
    }
    .title {
      text-align: center;
    }
  }
  .white-btn {
    color: white;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
    @include respond(tab-port) {
      width: 90%;
    }
    .signin-input {
      width: 100%;
      margin: 1rem 0;
      border: none;
      padding: 1.5rem;
      font-size: 16px;
      background-color: transparent;
      border: 1px solid transparent;
      border-bottom: 1px solid white;
      color: white;

      /* Change Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: white !important;
        -webkit-box-shadow: 0 0 0 30px inset !important;
        border-bottom: 1px solid white !important;
        border: 0px;
      }
      &::placeholder {
        font-family: $quicksand;
        color: white;
        opacity: 0.4;
        background-color: transparent;
      }
      &:focus {
        outline: none;
        background-color: transparent;
        border-bottom: 1px solid #e4e9ed;
      }
    }
    .signin__button {
      color: white;
      font-family: $quicksand;
      font-size: 1.6rem;
      border: none;
      background: transparent;
      text-transform: uppercase;
      border: 1px solid white;
      padding: 1.2rem 4rem;
      cursor: pointer;
      margin-top: 2rem;
      transition: all 0.2s ease-in-out;
      border-radius: 12px;
      &:hover {
        transform: scale(1.1);
      }
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  &__forgot {
    margin-top: 4rem;
    text-transform: uppercase;
    color: white;
    @include media("<phone") {
      margin-top: 2rem;
    }

    @include media(">=phone", "<lgphone") {
      margin-top: 2rem;
    }
    @include media(">=lgphone", "<tablet") {
      margin-top: 2rem;
    }

    h4 {
      margin-top: 2rem;
    }
    a {
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
