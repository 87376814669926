[data-theme="light"] {
  --bg-color: #fff;
  --text-color: #000;
  --menu-icon-color: #000;
  --bg-secondary: #ecf0f1;
  --bg-progress-bar: #ecf0f1;
  --media-player: #fff;
  --textbar-color: rgba(255, 255, 255, 0.6);
  --mobile-player-color: #fff;
}

[data-theme="dark"] {
  --bg-color: #000;
  --text-color: #fff;
  --menu-icon-color: #fff;
  --bg-secondary: #1a1a1a;
  --bg-progress-bar: rgba(0, 0, 0, 0.2);
  --media-player: #1a1a1a;
  --textbar-color: rgba(255, 255, 255, 0.1);
  --mobile-player-color: #1a1a1a;
}

.sound-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: var(--bg-color);
  color: var(--text-color);
  @include media("<phone") {
  }

  @include media(">=phone", "<lgphone") {
  }
  @include media(">=lgphone", "<tablet") {
  }
  @include media(">=tablet", "<=desktop") {
  }
  @include media(">desktop", "<mddesktop") {
  }
  @include media(">=4k") {
  }

  transition: all 0.2s ease-in-out;

  .sound-main-wrapper {
    width: 90%;
    max-width: 980px;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0 !important;
      -webkit-appearance: none;
      height: 0;
    }
    @include media("<phone") {
      width: 100%;
      height: 100%;
      margin-top: 0;
      display: block;
      overflow: hidden;
    }

    @include media(">=phone", "<lgphone") {
      width: 100%;
      height: 100%;
      margin-top: 0;
      display: block;
      overflow: hidden;
    }
    @include media(">=lgphone", "<tablet") {
      margin-top: 0;
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden;
    }

    .sound-nav {
      display: flex;
      justify-content: space-between;
      padding: 10px 25px;
      margin-top: 2vh;
      position: absolute;
      top: 0;
      width: 100%;
      max-width: 980px;
      h1 {
        font-size: 2rem;
        font-family: $quicksand;
      }

      .menu-wrapper {
        display: flex;
        .main-button {
          font-size: 2rem !important;
          font-family: $quicksand !important;
        }
        button {
          width: 24px;
          height: 24px;
          background: transparent;
          border: none;
          cursor: pointer;
          margin-right: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .menu-icon {
        position: relative;
        cursor: pointer;
        margin-bottom: 4px;

        .menu-icon__cheeckbox {
          position: relative;
          cursor: pointer;
          -webkit-touch-callout: none;
          opacity: 0;
        }
        div {
          margin: auto;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          width: 20px;
          height: 10px;
        }
        span {
          position: absolute;
          display: block;
          width: 100%;
          height: 2px;
          background-color: var(--menu-icon-color);
          border-radius: 1px;
          transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

          &:first-of-type {
            top: 0;
          }
          &:last-of-type {
            bottom: 0;
          }
        }
        &.active,
        .menu-icon__cheeckbox:checked + div {
          span {
            &:first-of-type {
              transform: rotate(45deg) translateX(-1px);
              top: 5px;
            }
            &:last-of-type {
              transform: rotate(-45deg) translateX(-1px);
              bottom: 5px;
            }
          }
        }

        &.active:hover span:first-of-type,
        &.active:hover span:last-of-type,
        &:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
        &:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
          width: 22px;
        }

        &:hover {
          // no need hover effect on mobile.
          @media (min-width: 1024px) {
            span:first-of-type {
              width: 26px;
            }

            span:last-of-type {
              width: 12px;
            }
          }
        }
      }
      .nav-content {
        width: 200px;
        position: absolute;
        background-color: var(--bg-secondary);
        color: var(--text-color);
        padding: 20px;
        border-radius: 15px;
        right: 20px;
        top: 40px;
        z-index: 10;
        li {
          padding: 10px 0px;
          cursor: pointer;
          font-size: 16px;
        }
        a {
          background-color: transparent;
          color: var(--text-color);
          font-family: $quicksand;
          border: none;
        }
        @include media("<phone") {
          right: 0px;
          top: 50px;
          padding: 5px 10px;
          width: 100%;
        }

        @include media(">=phone", "<lgphone") {
          right: 0px;
          top: 50px;
          padding: 5px 10px;
          width: 100%;
        }
        @include media(">=lgphone", "<tablet") {
          right: 0px;
          top: 50px;
          padding: 5px 10px;
          width: 100%;
        }
      }
    }

    .main-content {
      margin-top: 1vh;
      font-size: 1rem;
      font-family: $quicksand;
      font-weight: 500;
      width: 100%;
      height: 100%;
      overflow: hidden;
      @include media("<phone") {
        width: 100%;
        margin-top: 80px;
      }

      @include media(">=phone", "<lgphone") {
        width: 100%;
        margin-top: 80px;
      }
      @include media(">=lgphone", "<tablet") {
        width: 100%;
        margin-top: 80px;
      }
      .card-wrapper {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(6, 1fr);
        overflow-x: scroll;
        grid-column-gap: 20px;
        padding: 10px;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          height: 0;
          width: 0 !important;
        }

        .card-btn {
          position: relative;
          cursor: pointer;
          height: 100%;
          width: 150px;
          border-radius: 30px;
          border: none;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          &:first-child {
            margin-left: 0px !important;
          }
          box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);

          @include media("<phone") {
            height: 100%;
            width: 120px;
            border-radius: 20px;
            margin-right: 10px !important;
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
          }

          @include media(">=phone", "<lgphone") {
            height: 100%;
            width: 120px;
            border-radius: 20px;
            margin-right: 10px !important;
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
          }
          @include media(">=lgphone", "<tablet") {
            height: 100%;
            width: 120px;
            border-radius: 20px;
            margin-right: 10px !important;
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
          h1 {
            position: absolute;
            font-size: 15px;
            margin-top: 25px;
            color: var(--text-color);
            @include media("<phone") {
              font-size: 12px;
              margin-top: 15px;
            }

            @include media(">=phone", "<lgphone") {
              font-size: 12px;
              margin-top: 25px;
            }
            @include media(">=lgphone", "<tablet") {
              font-size: 12px;
              margin-top: 15px;
            }
          }
          &:first-child {
            margin: 0;
          }
        }
      }
    }
    .sound-player-wrapper {
      margin-top: 0vh;
      display: flex;
      height: 450px;
      overflow: hidden;
      padding: 10px;

      @include media("<phone") {
        padding: 0px;
        height: 60%;
      }

      @include media(">=phone", "<lgphone") {
        padding: 0px;
        height: 60%;
      }
      @include media(">=lgphone", "<tablet") {
        padding: 0px;
        height: 60%;
      }

      .sound-player {
        width: 40%;
        height: 100%;
        background-color: var(--media-player);
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);

        @include media("<phone") {
          display: none;
        }

        @include media(">=phone", "<lgphone") {
          display: none;
        }
        @include media(">=lgphone", "<tablet") {
          display: none;
        }
        .report-btn {
          background: transparent;
          border: none;
          position: absolute;
          left: 25px;
          top: 25px;
          cursor: pointer;
          img {
            height: 30px;
          }
        }
        .player-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          h1 {
            text-align: center;
            font-size: 3rem;
            font-weight: 400;
            margin: 0;
          }
          h2 {
            text-align: center;
            font-size: 1.5rem;
            margin: 0;
            opacity: 0.5;
          }
          .player-icon {
            width: 170px;
            height: 170px;
            background-color: var(--bg-media-player);
            border-radius: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);

            .mid-circle {
              position: absolute;
              height: 30px;
              width: 30px;
              background-color: white;
              border-radius: 100%;
            }
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
        .controls {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 40%;
          margin-top: 20px;
          .control-icon {
            border-radius: 100%;
            border: none;
          }
          .small-btn {
            height: 20px;
            width: 20px;
            background: transparent;
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
          .medium-btn {
            height: 50px;
            width: 50px;
            background: transparent;
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
          .big-btn {
            height: 60px;
            width: 60px;
            background: transparent;
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
        }
        .beat-actions {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          .beat-action {
            margin: 0px 10px;
            border: none;
            background: transparent;
            height: 30px;
            width: 30px;
            img {
              object-fit: contain;
              height: 20px;
              width: 20px;
            }
          }
        }
        .progress-wrapper {
          width: 100%;
          max-width: 260px;
          height: 3px;
          background: var(--bg-progress-bar);
          border-radius: 3px;
          overflow: hidden;
          margin-top: 20px;
          cursor: pointer;
        }
        .progress-wrapper .progress-bar-main {
          display: block;
          height: 100%;
          background: linear-gradient(
            90deg,
            #ffd33d,
            #ea4aaa 17%,
            #b34bff 34%,
            #01feff 51%,
            #ffd33d 68%,
            #ea4aaa 85%,
            #b34bff
          );
          background-size: 300% 100%;
          -webkit-animation: progress-animation 2s linear infinite;
          animation: progress-animation 2s linear infinite;
        }
      }
      .sound-player-mobile {
        position: fixed;
        width: 100%;
        z-index: 10;
        margin: 0;
        padding: 0;
        bottom: 0;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        background-color: var(--mobile-player-color);
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 1px -1px 49px -9px rgba(0, 0, 0, 0.42);
        -webkit-box-shadow: 1px -1px 49px -9px rgba(0, 0, 0, 0.42);
        -moz-box-shadow: 1px -1px 49px -9px rgba(0, 0, 0, 0.42);
        @include media(">=tablet") {
          display: none;
        }
        .report-btn {
          background: transparent;
          border: none;
          position: absolute;
          left: 20px;
          top: 20px;
          cursor: pointer;
          img {
            height: 20px;
          }
        }
        .circle-player {
          position: absolute;
          height: 76px;
          width: 76px;
          border-radius: 100%;
          top: -50px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          background-color: black;
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
          .white-circle {
            position: absolute;
            height: 26px;
            width: 26px;
            background: white;
            z-index: 10;
            border-radius: 100%;
          }
        }
        .actions {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          padding: 10px;
          .beat-action {
            margin: 0px 10px;
            border: none;
            background: transparent;
            height: 20px;
            width: 20px;
            img {
              height: 15px;
              width: 15px;
            }
          }
        }
        .controls {
          width: 50%;
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          button {
            background-color: transparent;
            border: none;
            img {
              height: 20px;
              width: 20px;
            }
          }
        }

        .progress-wrapper {
          width: 100%;
          max-width: 260px;
          height: 3px;
          background: var(--bg-progress-bar);
          border-radius: 3px;
          overflow: hidden;
          margin-top: 20px;
          cursor: pointer;
        }
        .progress-wrapper .progress-bar-main {
          display: block;
          height: 100%;
          background: linear-gradient(
            90deg,
            #ffd33d,
            #ea4aaa 17%,
            #b34bff 34%,
            #01feff 51%,
            #ffd33d 68%,
            #ea4aaa 85%,
            #b34bff
          );
          background-size: 300% 100%;
          -webkit-animation: progress-animation 2s linear infinite;
          animation: progress-animation 2s linear infinite;
        }
      }
      .songs-loading {
        width: 65%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media("<=tablet") {
          width: 100%;
        }
      }
      .songs-loading-main {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .sound-beats-list {
        width: 60%;
        height: 100%;
        padding: 10px 0px;
        margin: 0px 20px;
        overflow: scroll;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          height: 0;
          width: 0 !important;
        }
        @include media("<phone") {
          width: 100%;
          margin: 0px 5px;
          margin-bottom: 40px;
        }

        @include media(">=phone", "<lgphone") {
          width: 100%;
          margin: 0px 5px;
          margin-bottom: 40px;
        }
        @include media(">=lgphone", "<tablet") {
          width: 100%;
          margin: 0px 5px;
          margin-bottom: 40px;
        }
        .sound-beat-item {
          width: 100%;
          padding: 5px;
          border-radius: 40px;
          margin: 10px 0px;
          cursor: pointer;
          .beat-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .beat-main {
              display: flex;
              align-items: center;
              .play-btn {
                width: 46px;
                height: 46px;
                border-radius: 100%;
                cursor: pointer;
                @include media("<phone") {
                  width: 36px;
                  height: 36px;
                }

                @include media(">=phone", "<lgphone") {
                  width: 36px;
                  height: 36px;
                }
                @include media(">=lgphone", "<tablet") {
                  width: 36px;
                  height: 36px;
                }
              }
              .playing-btn {
                width: 46px;
                height: 46px;
                border-radius: 100%;
                cursor: pointer;
                background: transparent;
                border: none;
              }
              .beat-name {
                margin-left: 20px;
                @include media("<phone") {
                  font-size: 8px;
                }

                @include media(">=phone", "<lgphone") {
                  font-size: 8px;
                }
                @include media(">=lgphone", "<tablet") {
                  font-size: 8px;
                }
                h3 {
                  opacity: 0.5;
                }
              }
            }
            .beat-actions {
              display: flex;
              justify-content: space-between;
              .beat-action {
                margin: 0px 10px;
                border: none;
                background: transparent;
                height: 15px;
                width: 15px;
                img {
                  object-fit: contain;
                  height: 15px;
                  width: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  // Export Section
  .export-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 100;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    .export-container {
      background: var(--bg-secondary);
      padding: 20px;
      border-radius: 12px;
      width: 320px;
      height: 550px;
      overflow: hidden;

      .export-wrapper {
        display: flex;
        flex-direction: column;
        font-family: $quicksand;
        overflow: hidden;
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h1 {
            font-size: 3rem;
            font-family: $quicksand;
          }
          button {
            background: transparent;
            border: none;
            img {
              height: 20px;
            }
          }
        }
        h2 {
          font-size: 1.5rem;
          opacity: 0.2;
        }
        .saved-songs {
          overflow: scroll;
          &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            width: 0 !important;
          }
          height: 350px;
        }
        .beats-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .beat-content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin: 10px 0px;
            h1 {
              font-size: 2rem;
            }
            h2 {
              font-size: 1rem;
            }
          }

          // Checkbox
          @supports (-webkit-appearance: none) or (-moz-appearance: none) {
            input[type="checkbox"] {
              --active: #fff;
              --active-inner: #1a1a1a;
              --focus: 1px rgba(0, 0, 0, 0.3);
              --border: #fff;
              --border-hover: #000;
              --background: #1a1a1a;
              --disabled: #f6f8ff;
              --disabled-inner: #e1e6f9;
              -webkit-appearance: none;
              -moz-appearance: none;
              height: 21px;
              width: 21px;
              outline: none;
              display: inline-block;
              position: relative;
              margin: 0;
              margin-left: 2px;
              cursor: pointer;
              border: 1px solid var(--bc, var(--border));
              background: var(--b, var(--background));
              transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
              &:after {
                content: "";
                display: block;
                left: 0;
                top: 0;
                position: absolute;
                transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
                  opacity var(--d-o, 0.2s);
              }
              &:checked {
                --b: var(--active);
                --bc: var(--active);
                --d-o: 0.3s;
                --d-t: 0.6s;
                --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
              }
              &:disabled {
                --b: var(--disabled);
                cursor: not-allowed;
                opacity: 0.9;
                &:checked {
                  --b: var(--disabled-inner);
                  --bc: var(--border);
                }
              }
              &:hover {
                &:not(:checked) {
                  &:not(:disabled) {
                    --bc: var(--border-hover);
                  }
                }
              }
              &:focus {
                box-shadow: 0 0 0 var(--focus);
              }

              & + label {
                font-size: 10px;
                line-height: 14.2px;
                display: inline-block;
                margin-left: 12px;
              }
            }
            input[type="checkbox"] {
              &:not(.switch) {
                border-radius: 3px;
                &:after {
                  width: 5px;
                  height: 9px;
                  border: 2px solid var(--active-inner);
                  border-top: 0;
                  border-left: 0;
                  left: 6px;
                  top: 3px;
                  transform: rotate(var(--r, 20deg));
                }
                &:checked {
                  --r: 43deg;
                }
              }
            }
          }
        }
        .bottom {
          font-size: 1.5rem;
          margin-top: 20px;
          button {
            margin-top: 10px;
            width: 100%;
            background-color: white;
            font-size: 2rem;
            padding: 10px 10px;
            border-radius: 12px;
            color: black;
            font-family: $quicksand;
          }
        }
        .success-message {
          margin-top: 10px;
          text-align: center;
          .success-anim {
            height: 200px;
          }

          img {
            width: 120px;
            margin-top: 20px;
          }
        }
        .message {
          margin-top: 70px;
        }
      }
    }
  }
}

// Report Section
.report-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 100;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  .report-container {
    background: var(--bg-secondary);
    padding: 20px;
    border-radius: 12px;
    width: 320px;
    height: 560px;
    overflow: hidden;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-size: 3rem;
        font-family: $quicksand;
      }
      button {
        background: transparent;
        border: none;
        img {
          height: 20px;
        }
      }
    }
  }
  .report-success-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .success-image {
    height: 200px;
    margin-top: 20px;
  }
  .success-text {
    margin-top: 80px;
    text-align: center;
    font-size: 2.5rem;
  }
  .song-title {
    font-size: 1rem;
  }
  // Checkbox
  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"] {
      --active: #fff;
      --active-inner: #1a1a1a;
      --focus: 1px rgba(0, 0, 0, 0.3);
      --border: #fff;
      --border-hover: #000;
      --background: #1a1a1a;
      --disabled: #f6f8ff;
      --disabled-inner: #e1e6f9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      width: 21px;
      outline: none;
      display: inline-block;
      position: relative;
      margin: 0;
      margin-left: 2px;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
      &:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
          opacity var(--d-o, 0.2s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
      }
      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }
      }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }

      & + label {
        font-size: 10px;
        line-height: 14.2px;
        display: inline-block;
        margin-left: 12px;
      }
    }
    input[type="checkbox"] {
      &:not(.switch) {
        border-radius: 3px;
        &:after {
          width: 5px;
          height: 9px;
          border: 2px solid var(--active-inner);
          border-top: 0;
          border-left: 0;
          left: 6px;
          top: 3px;
          transform: rotate(var(--r, 20deg));
        }
        &:checked {
          --r: 43deg;
        }
      }
    }
  }
  .report-song__details {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .song-title {
      font-size: 2rem;
    }
    .song-producer {
      opacity: 0.2;
    }
  }
  .report-song__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .report-type {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 10px 0px;
      font-size: 2rem;
    }
    textarea {
      background: var(--textbar-color);
      border: none;
      color: var(--text-color);
      border-radius: 8px;
    }
  }
  button {
    background: var(--text-color);
    color: var(--bg-color);
    border-radius: 8px;
  }
}

// Animations

@keyframes unchecked {
  from {
    background-image: radial-gradient(
      ellipse at center,
      var(--primary) 0%,
      var(--primary) 25%,
      var(--secondary) 25.1%,
      var(--secondary) 100%
    );
    background-position: 100% 50%;
  }
  to {
    background-image: radial-gradient(
      ellipse at center,
      var(--primary) 0%,
      var(--primary) 25%,
      var(--secondary) 25.1%,
      var(--secondary) 100%
    );
    background-position: 50% 50%;
  }
}

@keyframes checked {
  from {
    background-image: radial-gradient(
      ellipse at center,
      var(--secondary) 0%,
      var(--secondary) 25%,
      var(--primary) 25.1%,
      var(--primary) 100%
    );
    background-position: 100% 50%;
  }
  to {
    background-image: radial-gradient(
      ellipse at center,
      var(--secondary) 0%,
      var(--secondary) 25%,
      var(--primary) 25.1%,
      var(--primary) 100%
    );
    background-position: 50% 50%;
  }
}

@keyframes unchecked-icon {
  from {
    transform: translateZ(12px);
  }
  to {
    transform: translateX(16px) rotateY(90deg) translateZ(12px);
  }
}

@keyframes checked-icon {
  from {
    transform: translateX(-16px) rotateY(-90deg) translateZ(12px);
  }
  to {
    transform: translateZ(12px);
  }
}
.bgAndBorderColor{
  background-color: var(--bg-secondary);
  border: 1px solid #b34bff !important;
}
.cancelIcon {
  color: var(--text-color);
  width: 74%;
  text-align: end;
  padding: 7px;
  font-size: 16px;
  padding-bottom: 10px;

}