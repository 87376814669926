.dashboard {
  width: 100%;
  @include respond(tab-port) {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: none;
  }
}
.not-available {
  display: none;
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
  margin-top: 20rem;
  padding: 3rem;
  @include respond(tab-port) {
    display: block;
  }
}
.dashboardLayout {
  display: flex;
  margin-top: 10rem;
  position: relative;
  width: 100%;
  @include respond(tab-port) {
    margin-top: -3rem;
    justify-content: center;
  }
  &__left {
    width: 20rem;
    background-color: white;
    @include respond(tab-port) {
      position: absolute;
      width: 20rem;
      left: -15rem;
    }
    .user {
      height: 15rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &__image {
        img {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
        }
      }
      &__name {
        font-size: 2rem;
      }
    }

    .navigation {
      display: flex;
      flex-direction: column;

      &__item {
        padding: 1.5rem 0 1.5rem 5rem;
        text-decoration: none;
        color: black;
        cursor: pointer;

        &:hover {
          background-color: $color-red;
        }

        &.active {
          background-color: rgba($color: $color-red, $alpha: 0.08);
          position: relative;
          &::before {
            content: "";
            height: 100%;
            background-color: $color-red;
            width: 1rem;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  &__right {
    width: 85%;
    padding: 4rem;
    @include respond(tab-port) {
    }

    .not-approved {
      text-align: center;
      font-family: $quicksand;
      font-size: 2rem;
    }
    .summary {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
      width: 100%;
      justify-content: space-between;
      @include respond(tab-port) {
      }
      &__item {
        width: 25rem;
        height: 20rem;
        background-color: white;
        margin: 2rem;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        border-radius: 1rem;
        border: 1px solid rgba($color: black, $alpha: 0.1);
        h3 {
          margin-bottom: 3rem;
        }
      }
    }
  }
}

.secret {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__form {
    width: 50rem;
  }

  &__codes {
    width: 50rem;

    .headings {
      display: flex;
      margin: 2rem 0;
      text-transform: uppercase;
      font-size: 2rem;
      div {
        width: 20rem;
      }
    }
    .codes {
      display: flex;
      margin-bottom: 0.5rem;
      font-family: $quicksand;
      div {
        width: 20rem;
      }
    }
  }
}
