.exportModal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba($color: #000, $alpha: 0.6);
  z-index: 20000;
  display: flex;
  justify-content: center;
  align-items: center;

  .export_container {
    width: 305px;
    height: 597px;
    background-color: white;

    .exportWidget {
      position: relative;

      .top {
        height: 97px;
        padding: 26px 0 0 26px;
        .title {
          h1 {
            font-size: 39px;
            font-family: "renner", sans-serif;
          }
          .close {
            position: absolute;
            top: 18px;
            right: 17px;
          }
        }
        .subtitle {
          font-size: 16px;
          margin-top: 5px;
        }
      }

      .success-message {
        height: 382px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .message {
          text-align: center;
          margin-top: 20px;
          span {
            color: #ec3432;
          }
        }
      }
      .saved-songs {
        height: 362px;
        overflow: hidden;
        padding: 29px 10px 10px 29px;
        .saved-songs_container {
          height: 100%;
          overflow-y: scroll;
          .song-container {
            margin-bottom: 20px;
            .song-label {
              display: flex;
              align-items: center;

              img {
                margin-right: 20px;
                width: 35px;
              }
              .song-details {
                font-size: 16px;
                text-transform: uppercase;
                .song {
                  font-family: "renner-light", sans-serif;
                }
              }
            }
          }
        }
      }

      .bottom {
        height: 138px;
        padding: 19px 29px 29px;
        .info-count {
          display: flex;
          width: 100%;
          justify-content: space-between;

          .info {
            font-size: 9.5px;
            text-transform: uppercase;
            font-family: "renner-bold", sans-serif;
          }
          .count {
            border: 2px solid black;
            font-size: 12px;
            padding: 0 5px;
            font-family: "renner-bold", sans-serif;
          }
        }
        .export-button {
          width: 100%;
          border: 2px solid black;
          background: transparent;
          text-transform: uppercase;
          padding: 15px 12px;
          font-size: 20px;
          font-family: "renner", sans-serif;
          margin-top: 10px;
          &:disabled {
            opacity: 0.4;
          }
        }
      }
    }
  }
}
