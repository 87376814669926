.producerProfile {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: space-between;
  padding: 15rem 0;
  align-items: center;
  flex-direction: column;
  position: relative;
  @include respond(tab-port) {
    padding: 3rem;
    justify-content: flex-start;
    height: auto;
  }

  p {
    padding: 1rem 0;
  }
  .confirm-email {
    p {
      padding: 1rem 0;
    }
  }

  .producer {
    width: 40%;
    // background-color: antiquewhite;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond(tab-port) {
      width: 95%;
    }
    &__name {
      text-transform: uppercase;
      font-size: 3rem;
    }
    &__title {
      font-family: $quicksand;
      text-transform: uppercase;
      // font-size: 2rem;
      margin: 1rem 0;
    }
    &__link {
      font-family: $quicksand;
      font-size: 1.4rem;
    }
    .download-empty {
      text-align: center;
      width: 100%;
      margin-left: 3rem;
      font-family: $quicksand;
    }
    &__downloads {
      height: 200px;
      overflow: hidden;
      @include respond(tab-port) {
        width: 100%;
      }

      .download-wrapper {
        width: 100%;
        height: 200px;
        overflow: scroll;
        .download-headings {
          margin: 2.5rem 0;
          text-transform: uppercase;
          display: grid;
          grid-template-columns: 230px 120px 120px;
          justify-items: center;
        }
        .download-details {
          font-family: $quicksand;
          justify-items: center;
          margin: 1rem 0;
          display: grid;
          grid-template-columns: 230px 120px 120px;
        }
      }
    }
    &__income {
      margin: 3rem 0;
      .income-heading {
        text-transform: uppercase;
      }
      .income {
        font-family: $quicksand;
      }
    }
  }
}
