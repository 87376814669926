.error-message {
  color: $color-red;
  margin: 1rem 0;
  font-size: 1.4rem;
  font-family: $quicksand;
}
.container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  position: relative;
  @include respond(tab-port) {
    padding: 2rem;
    justify-content: flex-start;
    height: auto;
  }
}

.white-btn {
  color: $color-red;
  font-family: $jost;
  font-size: 1.5rem;
  border: none;
  background: none;
  text-transform: uppercase;
  box-shadow: 0 0 10px #e0e0e0;
  padding: 1.2rem 3rem;
  min-width: 13rem;
  text-align: center;
  cursor: pointer;
  margin-top: 2rem;

  &.ns {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.black {
    color: black;
    border: 2px solid black;
    border-radius: 8px;
    font-weight: bold;
    padding: 1.2rem 4rem;
  }

  &.red {
    box-shadow: 0 0 10px rgba($color: $color-red, $alpha: 0.15);
  }
  &.font2 {
    font-family: $quicksand;
  }
}

.checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.double {
  display: flex;
  @include respond(phone) {
    flex-direction: column;
  }
}

.input-text,
.input-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border: none;
  margin: 2rem 0;
  border-bottom: 1px solid $color-red;
  padding: 1rem;
  font-family: $quicksand;
  font-size: 16px;
  &::placeholder {
    font-family: $quicksand;
  }
  &:focus {
    outline: none;
  }
}
.input-select option:not(:checked) {
  color: black;
}
.double-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > * {
    width: 45%;
  }
}

.input-label {
  display: block;
  font-family: $quicksand;
  margin: 2.5rem 0 0.5rem 0;
  font-size: 1.4rem;
}

.title {
  font-family: $jost;
  text-transform: uppercase;
  font-size: 3rem;
  @include respond(tab-port) {
    font-size: 2.5rem;
  }
}

.loud-window {
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(50, 50, 50, 0.2);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 3rem;
  height: 600px;
  overflow-y: scroll;

  &.fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .top {
    width: 100%;
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
    }
    h1 {
      font-size: 4rem;
    }
    img {
      cursor: pointer;
    }
  }
}

.window-btn {
  width: 100%;
  border: 2px solid black;
  background: transparent;
  text-transform: uppercase;
  padding: 10px 0;
  font-size: 20px;
  font-family: "renner", sans-serif;
  margin-top: 10px;
  cursor: pointer;
  &:disabled {
    opacity: 0.4;
  }
}

.icon-btn {
  width: 100%;
  border: none;
  background: none;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "renner", sans-serif;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    background-color: rgb(204, 201, 201);
  }
}

.d-flex {
  display: flex;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}
.w-33 {
  width: 33%;
}
.w-20 {
  width: 20%;
}
.w-15 {
  width: 15%;
}
.w-10 {
  width: 10%;
}
.align-center {
  align-items: center;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}

.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.ml-2 {
  margin-left: 2rem;
}
