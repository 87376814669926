.producerBeats {
  width: 40%;
  // background-color: antiquewhite;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include respond(tab-port) {
    width: 95%;
  }
  &__title {
    font-family: $quicksand;
    text-transform: uppercase;
    font-size: 2rem;
    margin: 1rem 0;
  }

  .beats-empty {
    text-align: center;
    width: 100%;
    margin-left: 3rem;
    font-family: $quicksand;
  }

  &__beats {
    align-self: flex-start;
    height: 500px;
    overflow: hidden;
    @include respond(tab-port) {
      width: 100%;
    }

    .beats-wrapper {
      width: 100%;
      height: 500px;
      overflow: scroll;
      .beats-headings {
        margin: 2.5rem 0;
        text-transform: uppercase;
        display: grid;
        grid-template-columns: 120px 120px 120px 120px 120px;
        justify-items: center;
      }
      .beats-details {
        font-family: $quicksand;
        justify-items: center;
        margin: 1rem 0;
        display: grid;
        grid-template-columns: 120px 120px 120px 120px 120px;

        div{
          img{
            width: 2rem;
            height: 2rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}
