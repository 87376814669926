*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  width: 100vw;
}

body {
  margin: 0 auto;
  font-family: $jost;
  font-size: 1.6rem;
  line-height: 1.4;
}
button {
  font-family: inherit;
}

ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
