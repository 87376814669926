.artisteReferral {
  overflow-x: hidden;
  .success {
    margin-top: 5rem;
    text-align: center;
    p {
      margin: 2rem 0;
      text-transform: uppercase;
    }
  }
  .referral-link {
    width: 100%;
    div {
      text-transform: uppercase;
    }
    p {
      font-size: 1rem;
    }
  }
  .referral-emails {
    width: 100%;
    margin-top: 1rem;
    input {
      margin: 1rem 0;
    }
  }
  .actions {
    display: flex;
    margin-top: 2rem;
    button {
      font-size: 1.4rem;
    }
  }
}
