.producerSignup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15rem;

  @include respond(tab-port) {
    padding-top: 5rem;
  }
  & > * {
    width: 40%;
    @include respond(tab-port) {
      width: 90%;
    }
  }

  &__title {
    font-size: 3rem;
    text-transform: uppercase;
    @include respond(tab-port) {
      font-size: 2rem;
    }
  }

  &__step1 {
    .info {
      font-family: $quicksand;
      font-size: 1.3rem;
    }
  }
  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 3rem;
      text-transform: uppercase;
    }
    p {
      margin: 2rem 0;
      font-family: $quicksand;
    }
    img {
      margin: 2rem 0;
    }
  }
}
