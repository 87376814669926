@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;400;500;700&display=swap");

@font-face {
  font-family: "renner-bold";
  src: url("./fonts/Renner\ 700\ Bold.otf");
}
@font-face {
  font-family: "renner";
  src: url("./fonts/Renner\ 400\ Book.otf");
}
@font-face {
  font-family: "renner-light";
  src: url("./fonts/Renner\ 200\ Thin.otf");
}

@import "./include-media.scss";

@import "./abstracts/variables.scss";
@import "./abstracts/mixins.scss";
@import "./base//base.scss";
@import "./base/utils.scss";
@import "./components/soundapp.scss";
@import "./components/exportModal.scss";
@import "./components/signin.scss";
@import "./components/home.scss";
@import "./components/membership.scss";
@import "./components/modal.scss";
@import "./components/signup.scss";
@import "./components/producerProfile.scss";
@import "./components/producerSignup.scss";
@import "./components/uploadBeat.scss";
@import "./components/forgotPassword.scss";
@import "./components/producerBeats.scss";
@import "./components/reportSong.scss";
@import "./components/adminDashboard.scss";
@import "./components/soundRedesign.scss";
@import "./components/artisteReferral.scss";
