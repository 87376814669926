.container {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgotPassword {
  width: 100%;

  @include respond(tab-port) {
    width: 100%;
  }
  p {
    margin: 2rem 0;
  }
  &__title {
    font-size: 3rem;
    text-transform: uppercase;
    font-family: $quicksand;
    margin-bottom: 3rem;
    @include respond(tab-port) {
      font-size: 2.5rem;
      padding-left: 2rem;
    }
  }
}

.forgotpass__card {
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  border-radius: 20px;
  width: 30%;
  -webkit-box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.1), 0 0 2px rgba(255, 255, 255, 0.1),
    0 0 2px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.08),
    0 0 20px rgba(255, 255, 255, 0.1), 0 0 150px rgba(255, 255, 255, 0.08);

  @include media("<phone") {
    width: 100%;
    padding: 10px;
  }

  @include media(">=phone", "<lgphone") {
    width: 90%;
    padding: 10px;
  }
  @include media(">=lgphone", "<tablet") {
    width: 90%;
    padding: 10px;
  }
  @include media(">=tablet", "<=desktop") {
    width: 50%;
    padding: 20px;
  }
  .input-text {
    width: 100%;
    margin: 1rem 0;
    border: none;
    padding: 1.5rem;
    font-size: 16px;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom: 1px solid white;
    color: white;
    &::placeholder {
      font-family: $quicksand;
      color: white;
      opacity: 0.4;
    }
    &:focus {
      outline: none;
      border: 1px solid transparent;
    }
    /* Change Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: white !important;
      -webkit-box-shadow: 0 0 0 30px inset !important;
      border-bottom: 1px solid white !important;
      border: 0px;
    }
    &::placeholder {
      font-family: $quicksand;
      color: white;
      opacity: 0.4;
      background-color: transparent;
    }
    &:focus {
      outline: none;
      background-color: transparent;
      border-bottom: 1px solid #e4e9ed;
    }
  }
  button {
    border-radius: 12px;
    transition: all 0.2s ease-in-out;
    color: white;
    border: 1px solid white;
    box-shadow: none;
    align-self: center;
    width: 100%;
    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(1);
    }
  }
}
