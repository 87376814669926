.home {
  width: 100%;
  display: flex;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  padding: 5rem;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: black;
  @include respond(tab-port) {
    flex-direction: column-reverse;
    padding: 3rem 3rem 0.5rem 3rem;
    justify-content: space-between;
  }

  video {
    position: absolute;
    z-index: 0;
    width: 100vw !important;
    height: 100vh;
    margin: 0;
    padding: 0;
    transform: scale(1.1);
    object-fit: cover;
  }

  &__policy {
    position: absolute;
    color: white;
    right: 5rem;
    bottom: 5rem;
    font-family: $quicksand;
    text-align: right;
    text-transform: uppercase;
    line-height: 1.4;
    font-size: 1.4rem;
    @include respond(tab-port) {
      display: none;
    }
  }
  &__middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    .heading-wrapper {
      @include respond(tab-port) {
        position: absolute;
        top: 35%;
      }
    }

    .shared-song {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 8rem;
      margin-bottom: 2rem;
      cursor: pointer;
      color: white;
      z-index: 10;
      @include respond(tab-port) {
        position: absolute;
        top: 25%;
      }
      .icon {
        margin-right: 1.5rem;
        img {
          width: 35px;
          height: 35px;
          object-fit: contain;
          margin-bottom: 0;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        .tap {
          color: white;
          font-weight: 700;
          text-transform: uppercase;
        }
        .song {
          display: flex;
          white-space: nowrap;
          p {
            margin-top: 0;
            text-transform: uppercase;
            font-weight: 600;
          }
          &-title {
            font-weight: 300 !important;
            margin-left: 1rem;
          }
        }
      }
    }
    .shared-btn{
      position: inherit !important;
      width:auto !important;
    }
    .heading-mob {
      text-align: center;
      position: absolute;
      bottom: 25%;
    }
    // img {
    //   height: 180px;
    //   width: 180px;
    //   object-fit: cover;
    //   cursor: pointer;
    // }
    a {
      margin: 2rem 0;
      padding: 1rem 4rem;
      border: 2px solid white;
      color: white;
      transition: all 0.2 ease-in-out;
      border-radius: 12px;
      z-index: 1;
      &:hover {
        background-color: white;
        color: black;
        border: 2px solid transparent;
      }
      @include respond(tab-port) {
        position: absolute;
        min-width: 150px;
        top: 50%;
      }
    }

    h1 {
      text-align: center;
      overflow: hidden;
      font-size: 5rem;
      line-height: 1.6;
      font-weight: 400;
      opacity: 0.8;
      width: 900px;
      font-family: $quicksand;
      color: white;
      @include respond(tab-port) {
        display: block;
        margin: 0;
        width: 100%;
        font-size: 2.2rem;
      }
    }
  }
  &__tabs {
    height: 100%;
    position: absolute;
    left: 5rem;
    top: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    @include respond(tab-port) {
      position: relative;
      top: -50px;
      left: 0;
      flex-direction: row;
      width: 301px;
      height: auto;
      margin-top: 7rem;
      justify-content: space-between;
    }

    @include respond(phone) {
      // align-self: flex-end;
    }
    .item {
      height: 300px;
      width: 200px;
      @include respond(tab-port) {
        width: auto;
        height: auto;
        text-align: center;
      }
      .image {
        cursor: pointer;
        img {
          width: 50px;
        }
      }
      .description {
        font-family: $quicksand;
        .title {
          text-transform: uppercase;
          font-size: 2.5rem;
          margin-bottom: 2rem;
          @include respond(tab-port) {
            font-size: 1.4rem;
          }
        }
        .message {
          line-height: 1.6;
          font-size: 1.4rem;
          width: 120px;
          @include respond(tab-port) {
            display: none;
          }
        }
      }
    }
  }
}

.home__header {
  width: 90%;
  position: absolute;
  top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;

  &.white {
    color: black;
    .menu-bar-wrapper {
      .nav {
        position: absolute;
        background-color: white;
        -webkit-box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
        box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
        color: black;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.1),
          0 0 2px rgba(0, 0, 0, 0.1), 0 0 150px rgba(0, 0, 0, 0.08),
          0 0 20px rgba(0, 0, 0, 0.1), 0 0 150px rgba(0, 0, 0, 0.08);
        ul {
          li {
            color: black;
          }
        }
      }
      .menu-icon {
        span {
          background-color: var(--bar-bg, black);
        }
      }
    }
  }
  @include media("<phone") {
    width: 100%;
    top: 1rem;
  }

  @include media(">=phone", "<lgphone") {
    width: 100%;
    top: 1rem;
  }
  @include media(">=lgphone", "<tablet") {
    width: 100%;
    top: 1rem;
  }
  @include media(">=tablet", "<=desktop") {
  }
  @include media(">desktop", "<mddesktop") {
  }
  @include media(">=4k") {
  }
  @include respond(tab-port) {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 5rem;
  }
  h1 {
    position: absolute;
    font-family: $quicksand;
    font-size: 2rem;
    letter-spacing: 10px;
    font-weight: 400;
    @include media("<phone") {
      top: 1rem;
      font-size: 1rem;
    }

    @include media(">=phone", "<lgphone") {
      top: 1rem;
      font-size: 1rem;
    }
    @include media(">=lgphone", "<tablet") {
      top: 1rem;
      font-size: 1rem;
    }
  }
  .menu-bar-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  .menu-bar {
    width: auto;
    cursor: pointer;
    @include respond(tab-port) {
      width: 301px;
      @include media("<phone") {
        width: 20px;
      }

      @include media(">=phone", "<lgphone") {
        width: 20px;
      }
      @include media(">=lgphone", "<tablet") {
        width: 30px;
      }
      @include media(">=tablet", "<=desktop") {
        width: 30px;
      }
    }
    img {
      width: 20px;
    }
    .incomplete {
      position: absolute;
      width: 18px;
      top: -10px;
      right: -18px;
    }
    .nav {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.05);
      -webkit-box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
      box-shadow: 10px 10px 51px -20px rgba(0, 0, 0, 0.75);
      color: black;
      width: 230px;
      top: 1rem;
      right: 0;
      height: auto;
      padding: 1rem 0;
      z-index: 500;
      border-radius: 12px;
      margin-top: 3rem;
      box-shadow: 0 0 2px rgba(255, 255, 255, 0.1),
        0 0 2px rgba(255, 255, 255, 0.1), 0 0 2px rgba(255, 255, 255, 0.1),
        0 0 150px rgba(255, 255, 255, 0.08), 0 0 20px rgba(255, 255, 255, 0.1),
        0 0 150px rgba(255, 255, 255, 0.08);
      &.active {
        @include respond(tab-port) {
          display: block;
        }
      }
      @include respond(tab-port) {
        position: absolute;
        width: 250px;
      }
      ul {
        li {
          margin: 2rem;
          text-transform: uppercase;
          color: white;
          &.mobile {
            display: none;
            @include respond(tab-port) {
              display: block;
            }
          }
        }
      }
    }
    .menu-icon {
      position: relative;
      cursor: pointer;
      margin-bottom: 4px;

      .menu-icon__cheeckbox {
        position: relative;
        cursor: pointer;
        -webkit-touch-callout: none;
        opacity: 0;
      }
      div {
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 20px;
        height: 10px;
      }
      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--bar-bg, white);
        border-radius: 1px;
        transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

        &:first-of-type {
          top: 0;
        }
        &:last-of-type {
          bottom: 0;
        }
      }
      &.active,
      .menu-icon__cheeckbox:checked + div {
        span {
          &:first-of-type {
            transform: rotate(45deg) translateX(-1px);
            top: 5px;
          }
          &:last-of-type {
            transform: rotate(-45deg) translateX(-1px);
            bottom: 5px;
          }
        }
      }

      &.active:hover span:first-of-type,
      &.active:hover span:last-of-type,
      &:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
      &:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
        width: 22px;
      }

      &:hover {
        // no need hover effect on mobile.
        @media (min-width: 1024px) {
          span:first-of-type {
            width: 26px;
          }

          span:last-of-type {
            width: 12px;
          }
        }
      }
    }
  }
  .audio {
    width: 20px;
    @include media("<phone") {
      width: 10px;
    }

    @include media(">=phone", "<lgphone") {
      width: 10px;
    }
    @include media(">=lgphone", "<tablet") {
      width: 10px;
    }
    @include media(">=tablet", "<=desktop") {
      width: 20px;
    }
    margin: 0px 20px;
    background-color: transparent;
    align-self: flex-end;
    position: relative;
    right: 0;
    cursor: pointer;
    @include respond(tab-port) {
      width: 46px;
    }
    img {
      width: 100%;
      @include media("<phone") {
        width: 20px;
        margin: 0 5px;
      }

      @include media(">=phone", "<lgphone") {
        margin: 0 5px;
        width: 20px;
      }
      @include media(">=lgphone", "<tablet") {
        margin: 0 5px;
        width: 20px;
      }
      @include media(">=tablet", "<=desktop") {
        width: 20px;
      }
    }
  }
}
.shared-parent{
  z-index: 10;
}
.shared-list{
  height: 410px;
  overflow-y: scroll;
  @include respond(tab-port){
    height: 349px;
  }
}
.display-flex{
  display: flex;
}
.justify-content-space-between{
  justify-content: space-between;
}
.justify-content-start{
  justify-content: start;
}
.width30percent{
  width: 30%;
}
.pt-10{
  padding-top: 10px;
}
.pb-10{
  padding-bottom: 10px;
}
.share-play-Btn{
  width: 46px !important;
  height: 46px !important;
  border-radius: 100% !important;
  cursor: pointer !important;
}
.align-item-center{
  align-items: center;
}
.download-icon{
    margin: 0px 10px;
    border: none;
    background: transparent;
    height: 30px;
    width: 30px;
}
.width300px{
  width: 400px;
  @media screen and (max-width:425px) {
    width: 100%;
  }
}
.text-align-center{
  text-align: center;
}
.pr10{
  padding-right: 10px;
};
.shared-list::-webkit-scrollbar * {
  color:red !important;
}
.drive-parent{
  border: 1px solid #fff;
    margin-top: 26px;
    min-height: 165px;
    border-radius: 21px;
    box-shadow: 0px 0px 11px 2px #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px !important;
}
.circle-icon{
  width: 110px;
    height: 110px;
    background-color: var(--bg-media-player);
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0px 0px 20px 10px #00000033;

}
.circle-mid{
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 100%;
}
.white-button-download{
  color: #fff;
    font-family: "Jost", sans-serif;
    font-size: 1rem;
    border: none;
    background: none;
    text-transform: uppercase;
    box-shadow: 0 0 10px #e0e0e0;
    padding: 1.2rem 3rem;
    min-width: 8rem;
    text-align: center;
    cursor: pointer;
    margin-top: 2rem;
    position: initial !important;
}
.font-size12{
  font-size: 12px;
}
.pb-20{
  padding-bottom: 20px;
}
.pt-70{
  padding-top: 70px;

}
.share-paragraph-text{
  padding-left: 10px;
}
.shareHome{
  @include respond(phone) {
    overflow: inherit;

  }
  @include respond(tab-port) {
    flex-direction: column-reverse;
    padding: 3rem 3rem 0.5rem 3rem;
    justify-content: start;
  }
}
.pt-5{
  padding-top: 5px;
}
.pb-5{
  padding: 5px;
}
.play-bg{
  background-color: rgb(26, 26, 26);
  border-radius: 50px;
  transform: none;
  cursor: pointer;
  transition: 0.6s;

}
.hover:hover{
  background-color: rgb(26, 26, 26);
  border-radius: 50px;
  transform: none;
  cursor: pointer;
  transition: 0.6s;

}
.coverArt{
  object-fit: contain;
  height: 100%;
}
.play-and-pause{
  margin: 0px 10px;
  border: none;
  background: transparent;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.producer-name-unbold{
  font-weight: 300;
  color: #c3c3c3;
}
.shared-list::-webkit-scrollbar { /* WebKit */
  width: 0px;
}
.animated-cd-player{
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@import url('https://fonts.googleapis.com/css2?family=Manrope');
@import url('https://fonts.googleapis.com/css2?family=Jost');


.signup__step1-card-wrapper {
  z-index: 10;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media("<phone") {
    display: initial;
  }

  @include media(">=phone", "<lgphone") {
    display: initial;

  }
  @media screen and (max-width:425px) and ( min-width:376px) {
    display: initial;
  }
  @include media(">=lgphone", "<tablet") {
    // display: initial;

  }
  @include media(">=tablet", "<=desktop") {
    display: initial;
    width: auto;

  }


  .transparent-blob {
    position: absolute;
    transform: scale(2.5);
  }
  .card {
    position: relative;
    height: 270px;
    width: 450px;
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    overflow: hidden;
    font-family: $jost;
    font-weight: 500;
    color: black;
    @include media("<phone") {
       height: 230px;
      width: 370px;
    }
    @media screen and (max-width:425px) and ( min-width:376px) {
      height: 228px;
      width: 401px;
    }
    @media screen and (max-width:320px) {
      height: 228px;
      width: 300px;
    }
    @media screen and ( max-width:375px) and (min-width: 321px) {
      height: 228px;
      width: 353px;
    }
    @media screen and ( max-width:768px) and (min-width: 426px) {
      height: 233px;
      width: 400px;
    }


    // @include media(">=phone", "<lgphone") {
    //    height: 230px;
    //   width: 370px;

    // }
    // @include media(">=lgphone", "<tablet") {
    //    height: 230px;
    //   width: 370px;

    // }
    @include media(">=tablet", "<=desktop") {
      width: 407px;

    }
  }

  .logo img,
  .chip img,
  .number,
  .name,
  .from,
  .to,
  .ring {
    position: absolute; /* All items inside card should have absolute position */
  }

  .logo img {
    top: 10px;
    right: 40px;
    width: 80px;
    height: auto;
    opacity: 0.8;
  }

  .chip img {
    top: 30px;
    left: 40px;
    width: 60px;
    height: auto;
    opacity: 0.8;
  }

  .number,
  .name,
  .from,
  .to {
    color: black;
    font-weight: 400;
    letter-spacing: 2px;
  }

  .number {
    left: 40px;
    top: 125px;
    font-family: "Nunito", sans-serif;
    font-size: 2.2rem;
  }

  .name {
    font-size: 2rem;
    left: 40px;
    bottom: 35px;
    text-transform: uppercase;
  }

  .from {
    font-size: 1.5rem;
    bottom: 40px;
    right: 110px;
  }

  .to {
    font-size: 1.5rem;
    bottom: 40px;
    right: 40px;
  }
}
.paymentCardInfoText{
    position: absolute;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 52px;
    color: #FFFFFF;
    width: 44%;
    text-align: center;
    @media screen and (max-width:768px) {
      font-size: 20px;
      width: 100%;
    }
    @include media(">desktop") {
    }
    @include media (">tablet", "<desktop") {
      font-size: 26px;
    }
}
.payment-parent{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}
.returnSoundBtn{
    position: absolute;
    font-weight: 400;
    font-size: 23px;
    line-height: 52px;
    color: #FFFFFF;
    border: 1px solid;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    @media screen and (max-width:786px) {
      font-size: 16px;

    }


}
.returnSoundBtnParent{
  width: 45%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  @media screen and (max-width:768px) {
    width: 100%;
  }

}
.returnSoundBtn:hover{
  transition: all 0.2 ease-in-out;
    box-shadow: 0 9px 15px rgba(255, 255, 255, 0.3);

}
.cardParent{
  padding-bottom: 0px;
  padding-top: 50px;
  // @include media("<phone") {
  //   flex-direction: column;
  //       padding-bottom: 25px;
  //   padding-top: 53px;
  // }

  // @include media(">=phone", "<lgphone") {
  //   flex-direction: column;
  //       padding-bottom: 25px;
  //   padding-top: 53px;

  // }
  @include media("<=tablet") {
    flex-direction: column;
    padding-bottom: 15px;
    padding-top: 0px;

  }
  @media screen and (min-width: 426px) and (max-width: 768px)  {
    align-items: center;
  }
  // @include media(">=tablet", "<=desktop") {
  //   flex-direction: column;
  //       padding-bottom: 25px;
  //   padding-top: 53px;

  // }
}
.text-align-left{
  text-align: left !important;
}
.btnParent{
  display: flex;
  justify-content: flex-start;
  width: 73vw;
}
.paymetnHeading{
  font-size: 26px;
  padding-bottom: 14px;
}
.videoTag {
  position: absolute;
  z-index: 0;
  width: 100vw !important;
  height: 100vh;
  margin: 0;
  padding: 0;
  transform: scale(1.1);
  object-fit: cover;
}
.paymentHome{
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-group{
  @include media("<phone") {
    padding: 11px;

 }

 @include media(">=phone", "<lgphone") {
  padding: 11px;


 }
 @include media(">=lgphone", "<tablet") {
  padding: 11px;


 }
 @include media(">=tablet", "<=desktop") {
  padding: 11px;


 }
}
.justify-content-space-around {
  justify-content: space-around;
}
.AddIconParent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    flex-direction: column;
}
.addIconChild{

  cursor: pointer;
}
.AddText{
  font-size: 47px;
    text-align: center;
    font-weight: lighter;
}
.addNewCardText{
  font-family: 'Manrope';
  font-weight: 600;
  font-size: 22px;
  line-height: 44px;
  letter-spacing: 0.15em;
  color: #000000;
  padding-top: 10px;
}
.addSVG{
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.7);;
  border-radius: 53px;
}
.cancelSVG{
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 53px;
  transform: rotate(45deg);
}
.card-group:hover{
  transform: scale(1.04);
}
// .addIconChilds{
//   display: none;
// }
.addIconChilds {
  opacity: 0;
  display: flex;
  justify-content: end;
  position: relative;
  top: 30px;
  z-index: 99;
  left: 26px;
  cursor: pointer;
  @media screen and (max-width: 425px)  {
    left: 225px;
    top: 45px;
  }
  @media screen and (max-width: 320px)  {
    left: 165px;
    top: 45px;
  }

}
.containesr{
@media screen and (min-width: 769px)  {
  height: 418px;
}
}

.containesr:hover > .addIconChilds {
  opacity: 1;
}
.addCardParent{

  @media screen and (min-width: 769px)  {
    height: 418px;
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .shared-btn {
    font-size: 8px;
  }
}

@media screen and (min-width: 376px) and (max-width: 411px) {
  .shared-btn {
    font-size: 11px;
  }
}
