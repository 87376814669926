.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba($color: black, $alpha: 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  
  .close-button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    background-color: white;
    cursor: pointer;
    padding: 1rem;
  }
}

.reactivate {
  margin-top: 5rem;
  margin-bottom: 8rem;
}
