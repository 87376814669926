.membership {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15rem 0;
  align-items: center;
  flex-direction: column;
  position: relative;
  text-transform: uppercase;
  @include respond(tab-port) {
    padding: 3rem;
    justify-content: flex-start;
    height: auto;
  }

  &__info {
    text-align: center;
    @include respond(tab-port) {
      margin-bottom: 10rem;
    }
    .title {
      font-family: $jost;
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }
    .details {
      font-family: $quicksand;
      font-size: 1.4rem;
    }
  }
  &__referral {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond(tab-port){
      width: 90%;
    }
    .heading {
      font-family: $quicksand;
      text-align: center;
      font-size: 2rem;
    }

    p {
      text-align: center;
      margin-top: 2rem;
      text-transform: none;
      font-family: $quicksand;
    }
  }

  &__incomplete {
    text-align: center;
    @include respond(tab-port) {
      margin-bottom: 10rem;
    }

    button {
      position: relative;
      img {
        position: absolute;
        width: 20px;
        top: -0.5rem;
        right: -0.5rem;
      }
    }
  }

  &__cancel {
    color: $color-red;
    background: transparent;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    font-family: $quicksand;
    font-size: 1.6rem;
  }
}

.complete-registration {
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(50, 50, 50, 0.2);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 3rem;
  height: 600px;
  overflow-y: scroll;
  justify-content: space-between;
  .top {
    width: 100%;
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
    }
    h1 {
      font-size: 4rem;
    }
    img {
      cursor: pointer;
    }
  }
  .download {
    text-transform: uppercase;
    border: 2px solid black;
    padding: 1rem 5rem;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 3rem;
  }
  .info {
    text-transform: uppercase;
    font-size: 1.1rem;
    margin-top: 2rem;
    line-height: 1.4;
    font-weight: 700;
  }
  .terms {
    margin: 3rem 0;
    .item {
      display: flex;
      cursor: pointer;
      align-items: flex-start;
      &:first-child {
        margin-bottom: 3rem;
      }
      img {
        margin-right: 2rem;
      }
      .details {
        h3 {
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 700;
          margin-bottom: 0.3rem;
        }
        p {
          font-family: $quicksand;
          font-size: 1.2rem;
        }
      }
    }
  }
}

.progress-bar {
  margin-top: 2rem;
  background-color: #dadada;
  height: 1px;
  width: 250px;
  position: relative;
  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 90%;
    background-color: $color-red;
  }
}

.membership-height{
  height: 100vh;
}
.membership-listener-height{
  height: 67vh;
}
